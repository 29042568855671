.about-garrett {
    position: relative;
    margin-top: 30px;
    &__logo {
        background-color: #564c0e;
        margin: 0 auto;
        padding: 40px 30px;
        text-align: center;
        @media (min-width: $screen-md-min) {
            bottom: 0;
            height: 296px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 264px;
            z-index: 1;
        }
        p {
            margin: 20px 0 0;
            font-size: 20px;
            color: #ffffff;
        }
    }
    &__feature {
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        height: 220px;
        margin-top: 15px;
        @media (min-width: $screen-md-min) {
            margin-top: 0;
        }
        p {
            bottom: 0;
            color: #ffffff;
            font-size: 20px;
            left: 0;
            margin: 0 15px;
            padding: 20px 15px;
            position: absolute;
            right: 0;
            text-shadow: 0px 5px 15px #000000, 0px -5px 15px #000000, 0px 0px 30px #000000, 0px 0px 10px #000000;
            top: auto;
            width: 66.66666%;
        }
        &--bg1 {
            background-image: url($image-path + "50-lat-doswiadczenia.jpg");
            @media (min-width: $screen-md-min) {
                margin-right: -15px;
            }
        }
        &--bg2 {
            background-image: url($image-path + "zroznicowane-wymagania.jpg");
            @media (min-width: $screen-md-min) {
                margin-left: -15px;
            }
            p {
                @media (min-width: $screen-md-min) {
                    left: auto;
                    text-align: right;
                }
            }
        }
        &--bg3 {
            background-image: url($image-path + "innowacyjne-rozwiazania.jpg");
            @media (min-width: $screen-md-min) {
                margin-right: -15px;
            }
            p {
                @media (min-width: $screen-md-min) {
                    bottom: auto;
                }
            }
        }
        &--bg4 {
            background-image: url($image-path + "satysfakcja-klientow.jpg");
            @media (min-width: $screen-md-min) {
                margin-left: -15px;
            }
            p {
                @media (min-width: $screen-md-min) {
                    bottom: auto;
                    left: auto;
                    text-align: right;
                }
            }
        }
    }
}