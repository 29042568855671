.accessories {
    &__item {
        margin-top: 30px;
        img {
            margin-bottom: 15px;
        }
        p {
            text-align: center;
        }
    }
}