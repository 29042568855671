.slider {
    margin-top: -80px;
    position: relative;
    &__indicators {
        bottom: 30px;
        display: inline-block;
        left: 0;
        list-style-image: none;
        list-style-position: outside;
        list-style-type: none;
        margin: 0 auto;
        padding: 10px;
        position: absolute;
        right: 0;
        text-align: center;
        width: 130px;
        z-index: 1;
    }
    &__indicator {
        border-radius: 50%;
        border: 2px solid $carousel-indicator-border;
        cursor: pointer;
        display: inline-block;
        height: 20px;
        margin: 0 5px;
        vertical-align: middle;
        width: 20px;
        &--active {
            background-color: $carousel-indicator-active;
            border: 2px solid $carousel-indicator-active;
        }
    }
    &__inner {
        position: relative;
        background-color: $carousel-inner;
    }
    &__item {
        @include transition-duration(1s);
        @include transition-property(opacity, visibility);
        @include transition-timing(linear);
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        visibility: hidden;
        &--active {
            opacity: 1;
            visibility: visible;
        }
    }
    &__caption {
        margin: auto 0;
        padding: 0 15px;
        position: absolute;
        width: 100%;
        &--service {
            background-color: $carousel-caption-service;
            bottom: 30px !important;
            padding: 30px 0;
            top: auto !important;
        }
    }
    &__image {
        margin-top: 30px;
    }
    &__heading,
    &__lead {
        text-align: center;
        text-shadow: 0 0 10px $carousel-caption;
    }
    &__heading {
        color: $carousel-caption-heading;
        font-size: 30px;
        font-weight: 900;
        line-height: 1.1;
        margin-bottom: 0;
        margin-top: 0;
        text-transform: uppercase;
        @media (min-width: $screen-sm-min) {
            font-size: 52px;
        }
        .slider__caption--service & {
            color: inherit;
            font-weight: 700;
            text-shadow: none;
            text-transform: uppercase;
            @media (min-width: $screen-md-min) {
                display: inline-block;
                font-size: 42px;
                padding-right: 30px;
                vertical-align: middle;
                width: 70%;
            }
            @media (min-width: $screen-md-min) {
                font-size: 52px;
            }
        }
        span {
            color: $white;
            display: block;
            margin-bottom: 15px;
            text-transform: none;
        }
    }
    &__contact {
        margin-top: 15px;
        text-align: center;
        @media (min-width: $screen-md-min) {
            border-left: 1px solid $color-1;
            display: inline-block;
            margin-top: 0;
            padding-left: 30px;
            text-align: left;
            vertical-align: middle;
            width: 30%;
        }
        p {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .fa {
            font-size: 24px;
            margin-right: 10px;
            vertical-align: middle;
        }
        a {
            color: inherit;
            display: inline-block;
            font-size: 18px;
            vertical-align: middle;
            @media (min-width: $screen-sm-min) {
                font-size: 22px;
            }
        }
    }
    &__lead {
        color: $carousel-caption-lead;
        font-size: 20px;
        margin-bottom: 0;
        @media (min-width: $screen-sm-min) {
            font-size: 26px;
        }
    }
}