.button-block {
    &--bg {
        background-color: $primary;
        margin: 30px auto;
        padding: 20px;
        text-align: center;
        width: 290px;
        @media (min-width: $screen-sm-min) {
            margin-top: 0;
            width: auto;
        }
        p {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 20px;
        }
    }
    &--xs-left {
        text-align: left;
    }
    &--xs-center {
        text-align: center;
    }
    &--xs-right {
        text-align: right;
    }
    &--sm-left {
        @media (min-width: $screen-sm-min) {
            text-align: left;
        }
    }
    &--sm-center {
        @media (min-width: $screen-sm-min) {
            text-align: center;
        }
    }
    &--sm-right {
        @media (min-width: $screen-sm-min) {
            text-align: right;
        }
    }
    &--md-left {
        @media (min-width: $screen-md-min) {
            text-align: left;
        }
    }
    &--md-center {
        @media (min-width: $screen-md-min) {
            text-align: center;
        }
    }
    &--md-right {
        @media (min-width: $screen-md-min) {
            text-align: right;
        }
    }
    &--lg-left {
        @media (min-width: $screen-lg-min) {
            text-align: left;
        }
    }
    &--lg-center {
        @media (min-width: $screen-lg-min) {
            text-align: center;
        }
    }
    &--lg-right {
        @media (min-width: $screen-lg-min) {
            text-align: right;
        }
    }
}

.button {
    border-width: 0;
    color: initial;
    display: inline-block;
    font-weight: 700;
    padding: 15px 20px;
    &--default,
    &--primary,
    &--success,
    &--info,
    &--warning,
    &--danger {
        .button-block--bg & {
            background-color: $color-2;
            border-bottom: 5px solid $color-1;
        }
    }
    &--default {
        background-color: $button-default;
    }
    &--primary {
        background-color: $button-primary;
    }
    &--success {
        background-color: $button-success;
    }
    &--info {
        background-color: $button-info;
    }
    &--warning {
        background-color: $button-warning;
    }
    &--danger {
        background-color: $button-danger;
    }
    &--lg {
        padding: 25px 30px;
    }
    &--sm {
        padding: 10px 15px;
    }
    &--xs {
        padding: 5px 10px;
    }
}