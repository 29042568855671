.alert {
    border-radius: 0;
    border-width: 2px;
    border-style: solid;
    margin-bottom: 20px;
    padding: 20px;
    text-align: center;
    &.alert-success {
        background-color: transparent;
        border-color: $alert-success;
        color: initial;
        .fa {
            color: $alert-success;
        }
    }
    &.alert-info {
        background-color: transparent;
        border-color: $alert-info;
        color: initial;
        .fa {
            color: $alert-info;
        }
    }
    &.alert-warning {
        background-color: transparent;
        border-color: $alert-warning;
        color: initial;
        .fa {
            color: $alert-warning;
        }
    }
    &.alert-danger {
        background-color: transparent;
        border-color: $alert-danger;
        color: initial;
        .fa {
            color: $alert-danger;
        }
    }
    .fa {
        font-size: 64px;
        margin-bottom: 10px;
        @media (min-width: $screen-sm-min) {
            display: table-cell;
            vertical-align: middle;
        }
    }
    p {
        @media (min-width: $screen-sm-min) {
            display: table-cell;
            padding-left: 20px;
            text-align: left;
            vertical-align: middle;
        }
    }
}