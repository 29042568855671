.site-footer {
    background-color: $footer-background;
    margin-top: 60px;
    &__wrapper {
        padding-bottom: 0;
        padding-top: 0;
        position: relative;
        @media (min-width: $screen-md-min) {
            overflow: hidden;
            padding-bottom: 30px;
            padding-top: 30px;
        }
    }
    &__map {
        height: 400px;
        position: relative;
        @media (min-width: $screen-md-min) {
            bottom: 0;
            height: auto;
            left: 0;
            margin: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    &__contact {
        @include transform-translate(0, 0);
        @include transition-duration(.8s);
        @include transition-property(transform, visibility);
        @include transition-timing(cubic-bezier(.19, 1, .22, 1));
        padding-bottom: 30px;
        padding-top: 30px;
        position: relative;
        @media (min-width: $screen-md-min) {
            background-color: rgba($footer-contact-background, 0.9);
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    &__btn-show-map {
        display: none;
        @media (min-width: $screen-md-min) {
            display: initial;
            margin-top: 30px;
        }
        >.fa {
            @media (min-width: $screen-md-min) {
                font-size: 32px;
                margin-right: 10px;
                vertical-align: middle;
            }
        }
        >.btn-title {
            @media (min-width: $screen-md-min) {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    &__btn-hide-map {
        display: none;
        @media (min-width: $screen-md-min) {
            bottom: 40%;
            box-shadow: 0 0 10px rgba($footer-background, 0.6);
            display: initial;
            font-size: 32px;
            left: 0;
            opacity: 0;
            padding: 20px;
            position: absolute;
            top: 40%;
            visibility: hidden;
            z-index: 1;
        }
        &.active {
            @media (min-width: $screen-md-min) {
                @include transition-delay(.8s);
                @include transition-duration(.8s);
                @include transition-property(left, opacity, visibility);
                @include transition-timing(cubic-bezier(.19, 1, .22, 1));
                left: -61px;
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &__form {
        color: $footer-text-mobile;
        @media (min-width: $screen-md-min) {
            color: initial;
        }
    }
    &__label {
        font-size: 16px;
        font-weight: 400;
    }
    &__form-control {
        background-color: lighten($footer-background, 10%);
        border-radius: 0;
        border-width: 0;
        color: $footer-text-mobile;
        @media (min-width: $screen-md-min) {
            background-color: #f0f0f0;
            color: $footer-text-desktop;
        }
    }
    &__validation-notice {
        color: $footer-validation-notice-error;
        font-size: 14px;
    }
    &__grecaptcha {
        >div {
            margin: auto;
            @media (min-width: $screen-md-min) {
                margin: 0;
            }
        }
    }
    &__notice {
        color: $footer-validation-notice-error;
        font-size: 14px;
        padding: 10px 0;
        text-align: center;
        @media (min-width: $screen-md-min) {
            text-align: left;
        }
        &.success {
            color: $footer-validation-notice-success;
            font-size: 16px;
        }
    }
    &__address {
        color: $footer-text-mobile;
        margin-top: 50px;
        text-align: center;
        @media (min-width: $screen-md-min) {
            color: initial;
        }
        img {
            margin-bottom: 15px;
        }
        p {
            margin: 0;
        }
        a {
            color: $footer-text-mobile;
            @media (min-width: $screen-md-min) {
                color: initial;
            }
        }
    }
    &__copyright {
        color: $footer-text-mobile;
        font-size: 13px;
        margin: 0;
        padding: 30px 0;
        text-align: center;
    }
}

.navigation-footer {
    background-color: darken($footer-background, 5%);
    margin-top: 30px;
    &__list {
        list-style-image: none;
        list-style-position: outside;
        list-style-type: none;
        margin: 0 -15px;
        overflow: hidden;
        padding: 15px 0;
        text-align: center;
        li {
            display: inline-block;
            padding-left: 15px;
            padding-right: 15px;
            a {
                color: $white;
            }
        }
    }
}