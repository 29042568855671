 /* CSS3 Transitions */

 @mixin transition-delay($tdelay...) {
     -webkit-transition-delay: $tdelay;
     transition-delay: $tdelay;
 }

 @mixin transition-duration($tduration...) {
     -webkit-transition-duration: $tduration;
     transition-duration: $tduration;
 }

 @mixin transition-property($tproperty...) {
     -webkit-transition-property: $tproperty;
     transition-property: $tproperty;
 }

 @mixin transition-timing($ttiming...) {
     -webkit-transition-timing-function: $ttiming;
     transition-timing-function: $ttiming;
 }
 /* CSS3 Animations */

 @mixin animation-delay($adelay...) {
     -webkit-animation-delay: $adelay;
     animation-delay: $adelay;
 }

 @mixin animation-direction($adirection...) {
     -webkit-animation-direction: $adirection;
     animation-direction: $adirection;
 }

 @mixin animation-duration($aduration...) {
     -webkit-animation-duration: $aduration;
     animation-duration: $aduration;
 }

 @mixin animation-fill-mode($afillmode...) {
     -webkit-animation-fill-mode: $afillmode;
     animation-fill-mode: $afillmode;
 }

 @mixin animation-iteration-count($aiterationcount...) {
     -webkit-animation-iteration-count: $aiterationcount;
     animation-iteration-count: $aiterationcount;
 }

 @mixin animation-name($aanimationname...) {
     -webkit-animation-name: $aanimationname;
     animation-name: $aanimationname;
 }

 @mixin animation-play-state($aplaystate...) {
     -webkit-animation-play-state: $aplaystate;
     animation-play-state: $aplaystate;
 }

 @mixin animation-timing-function($atiming...) {
     -webkit-animation-timing-function: $atiming;
     animation-timing-function: $atiming;
 }
 /* CSS3 Transform */

 @mixin transform-translate($tvalue...) {
     -webkit-transform: translate($tvalue);
     transform: translate($tvalue);
 }

 @mixin transform-scale($svalue...) {
     -webkit-transform: scale($svalue);
     transform: scale($svalue);
 }

 @mixin transform-rotate($rvalue...) {
     -webkit-transform: rotate($rvalue);
     transform: rotate($rvalue);
 }