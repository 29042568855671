@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.preloader {
    @include transition-duration(0.5s);
    @include transition-property(bottom, visibility);
    @include transition-timing(cubic-bezier(0.6, -0.28, 0.74, 0.05));
    background-color: $preloader-background-color;
    bottom: 0%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: visible;
    z-index: 9999;
    &__spinner {
        background-image: url($image-path + "garrett-logo.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 148px auto;
        border-radius: 10%;
        bottom: 0;
        height: 180px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 180px;
        &::after {
            @include animation-duration(3s);
            @include animation-iteration-count(infinite);
            @include animation-name(spin);
            @include animation-timing-function(ease);
            border-radius: 50%;
            border-top: 2px solid $preloader-spinner-border;
            bottom: 0;
            content: "";
            height: 240px;
            left: -30px;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 240px;
        }
    }
    &.disable {
        bottom: 100%;
        visibility: hidden;
    }
}