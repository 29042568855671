body {
    background-color: $page-background;
}

section,
article {
    padding-top: 30px;
    @media (min-width: $screen-sm-min) {}
    &:first-child {
        padding-top: 80px;
    }
}