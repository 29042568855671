.navigation {
    @include transition-duration(.5s);
    @include transition-property(background-color);
    @include transition-timing(linear);
    background-color: $responsive-navigation-background;
    padding-bottom: 10px;
    padding-top: 10px;
    position: fixed;
    width: 100%;
    z-index: 3;
    &--shrink {
        background-color: $responsive-navigation-background;
    }
    &__header {
        position: relative;
        .desktop & {
            @media (min-width: $screen-lg-min) {
                float: left;
            }
        }
    }
    &__logo {
        display: inline-block;
        height: 35px;
        vertical-align: middle;
        @media (min-width: $screen-lg-min) {
            height: 42px;
        }
        &-img {
            display: block;
            max-height: 100%;
            width: auto;
        }
    }
    &__hamburger-menu {
        bottom: 0;
        cursor: pointer;
        height: 24px;
        margin: auto 0;
        position: absolute;
        right: 0;
        top: -4px;
        .desktop & {
            @media (min-width: $screen-lg-min) {
                display: none;
            }
        }
    }
    &__hamburger-name,
    &__hamburger-button {
        display: inline-block;
        vertical-align: middle;
    }
    &__hamburger-name {
        color: $responsive-navigation-hamburger;
        font-size: 18px;
        margin-right: 5px;
        text-transform: uppercase;
    }
    &__hamburger-button {
        background-color: $responsive-navigation-hamburger;
        height: 4px;
        position: relative;
        width: 30px;
        &::before,
        &::after {
            @include transition-duration(.3s);
            @include transition-property(transform);
            @include transition-timing(cubic-bezier(0.22, 0.61, 0.36, 1));
            background-color: $responsive-navigation-hamburger;
            content: "";
            height: 4px;
            position: absolute;
            width: 30px;
        }
        &::before {
            top: 9px;
        }
        &::after {
            top: -9px;
        }
        .active & {
            visibility: hidden;
            &::before,
            &::after {
                visibility: visible;
                top: 0;
            }
            &::before {
                @include transform-rotate(45deg);
            }
            &::after {
                @include transform-rotate(-45deg);
            }
        }
    }
}

.navigation-menu {
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    @media (min-width: $screen-lg-min) {
        .desktop & {
            float: right;
        }
    }
    li {
        border-bottom: 1px dotted lighten($responsive-navigation-background, 30%);
        padding-right: 57px;
        @media (min-width: $screen-lg-min) {
            .desktop & {
                border-bottom-width: 0;
                padding-right: 0;
                position: relative;
            }
        }
        &.heading {
            border-bottom-width: 0;
            padding-right: 0;
            @media (min-width: $screen-lg-min) {
                .desktop & {
                    display: none;
                }
            }
        }
        a {
            color: $responsive-navigation-text;
            display: inline-block;
            font-size: 16px;
            padding: 8px 0;
            text-transform: uppercase;
            vertical-align: middle;
            @media (min-width: $screen-lg-min) {
                .desktop & {
                    padding: 10px 20px;
                    &:hover {
                        background-color: $responsive-navigation-text-hover-background;
                        color: $responsive-navigation-text-hover;
                    }
                }
            }
            &.contact {
                color: $responsive-navigation-contact;
                @media (min-width: $screen-lg-min) {
                    .desktop & {
                        font-size: 22px;
                        padding: 0 15px;
                        &:hover {
                            background-color: transparent;
                            color: $responsive-navigation-contact;
                        }
                    }
                }
                .fa {
                    vertical-align: middle;
                }
                span {
                    display: inline-block;
                    margin-left: 10px;
                    text-transform: none;
                    vertical-align: middle;
                    @media (min-width: $screen-lg-min) {
                        .desktop & {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .main-menu,
    .sub-menu {
        @include transition-duration(.3s);
        @include transition-property(left, opacity, top, visibility);
        @include transition-timing(cubic-bezier(0.22, 0.61, 0.36, 1));
        list-style-image: none;
        list-style-position: outside;
        list-style-type: none;
        margin: 0;
        opacity: 0;
        padding: 15px 15px 15px 65px;
        position: absolute;
        top: 0;
        visibility: hidden;
        @media (min-width: $screen-lg-min) {
            .desktop & {
                height: auto !important;
                width: auto;
            }
        }
        &::before {
            background-color: $responsive-navigation-main-sidebar;
            color: $responsive-navigation-main-sidebar-icon;
            font-family: $font-family-fontawesome;
            height: 100%;
            left: 0;
            position: absolute;
            text-align: center;
            top: 0;
            width: 50px;
        }
    }
    .main-menu {
        background-color: $responsive-navigation-main;
        overflow: hidden;
        width: 100%;
        @media (min-width: $screen-sm-min) {
            right: 0;
            width: 50%;
        }
        .desktop & {
            @media (min-width: $screen-sm-min) {
                right: 0;
                width: 50%;
            }
            @media (min-width: $screen-lg-min) {
                background-color: transparent;
                opacity: 1;
                overflow: visible;
                padding: 0;
                position: static;
                visibility: visible;
                width: auto;
            }
        }
        >li {
            @media (min-width: $screen-lg-min) {
                .desktop & {
                    display: inline-block;
                }
            }
            >.drop-down {
                &::after {
                    @media (min-width: $screen-lg-min) {
                        .desktop & {
                            content: "\f107";
                            font-family: $font-family-fontawesome;
                            margin-left: 5px;
                        }
                    }
                }
                &~.sub-menu {
                    @media (min-width: $screen-lg-min) {
                        .desktop & {
                            left: 0;
                            top: 36px;
                        }
                    }
                }
                &:hover~.sub-menu,
                &~.sub-menu:hover {
                    @media (min-width: $screen-lg-min) {
                        .desktop & {
                            opacity: 1;
                            top: 46px;
                            visibility: visible;
                        }
                    }
                }
            }
        }
        &::before {
            content: "\f097";
            font-size: 28px;
            padding-top: 10px;
            @media (min-width: $screen-lg-min) {
                .desktop & {
                    display: none;
                }
            }
        }
        &.active {
            opacity: 1;
            top: 10px;
            visibility: visible;
        }
    }
    .sub-menu {
        background-color: $responsive-navigation-main;
        left: 100%;
        width: 100%;
        z-index: 1;
        @media (min-width: $screen-lg-min) {
            .desktop & {
                padding: 0;
            }
        }
        &::before {
            content: "\f104";
            font-size: 38px;
            padding-top: 2px;
            @media (min-width: $screen-lg-min) {
                .desktop & {
                    display: none;
                }
            }
        }
        &.active {
            left: 0%;
            opacity: 1;
            visibility: visible;
        }
        .sub-menu-name {
            background-color: $responsive-navigation-main-sidebar;
            border-radius: 2px;
            color: $responsive-navigation-text;
            display: block;
            font-size: 18px;
            margin-bottom: 10px;
            padding: 5px 10px;
            text-align: center;
        }
        .drop-down {
            &::after {
                @media (min-width: $screen-lg-min) {
                    .desktop & {
                        bottom: 0;
                        content: "\f105";
                        font-family: $font-family-fontawesome;
                        height: 20px;
                        line-height: 20px;
                        margin: auto 0;
                        position: absolute;
                        right: 10px;
                        top: 0;
                    }
                }
            }
            &~.sub-menu {
                @media (min-width: $screen-lg-min) {
                    .desktop & {
                        left: 90%;
                        top: 0;
                    }
                }
            }
            &:hover~.sub-menu,
            &~.sub-menu:hover {
                @media (min-width: $screen-lg-min) {
                    .desktop & {
                        left: 100%;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
    .sub-menu-toggle-back,
    .sub-menu-toggle-next {
        color: $responsive-navigation-text;
        cursor: pointer;
        font-family: $font-family-fontawesome;
        position: absolute;
        @media (min-width: $screen-lg-min) {
            .desktop & {
                display: none;
            }
        }
    }
    .sub-menu-toggle-back {
        height: 100%;
        left: 0;
        top: 0;
        width: 50px;
    }
    .sub-menu-toggle-next {
        height: 42px;
        right: 15px;
        text-align: center;
        width: 42px;
        &::before {
            content: "\f105";
            font-size: 36px;
            line-height: 42px;
        }
    }
}