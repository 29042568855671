.table-striped {
    >thead {
        >tr:first-child {
            background-color: $primary;
            >th {
                padding-bottom: 15px;
                padding-top: 15px;
            }
        }
    }
}