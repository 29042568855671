.gallery {
    margin-bottom: 30px;
    margin-top: -30px;
    &__item {
        background-color: $white;
        margin-top: 30px;
        overflow: hidden;
        padding: 10px;
        position: relative;
        &--animate {
            @media (min-width: $screen-sm-min) {
                padding-bottom: 74px;
            }
        }
        a {
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }
    &__caption {
        margin-top: 10px;
        .gallery__item--animate & {
            @media (min-width: $screen-sm-min) {
                @include transition-duration(.5s);
                @include transition-property(bottom);
                @include transition-timing(ease);
                left: 10px;
                position: absolute;
                right: 10px;
            }
        }
        $class: gallery__item;
        .#{$class}:hover & {
            @media (min-width: $screen-sm-min) {
                bottom: 0 !important;
            }
        }
        h3 {
            background-color: $primary;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.2;
            margin: 15px 0 0;
            padding: 15px;
            text-align: center;
            @media (min-width: $screen-sm-min) {
                margin: 0 0 10px;
                text-align: left;
            }
            .gallery__item--animate & {
                @media (min-width: $screen-sm-min) {
                    margin: 0;
                }
            }
            span {
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        p {
            margin: 0;
            padding: 15px 15px 0 15px;
            @media (min-width: $screen-sm-min) {
                background-color: $white;
                padding: 0 15px;
            }
            .gallery__item--animate & {
                @media (min-width: $screen-sm-min) {
                    padding: 15px 15px 20px 15px;
                }
            }
        }
    }
}