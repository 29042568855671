.service-steps {
    border-left: 2px dashed $color-1;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin: 30px 0 0 15px;
    padding: 0;
    li {
        padding-bottom: 30px;
        padding-left: 30px;
        position: relative;
        @media (min-width: $screen-sm-min) {
            padding-left: 130px;
        }
        &:last-child {
            padding-bottom: 0;
        }
        &::before {
            background-color: $color-1;
            border-radius: 50%;
            content: "";
            height: 20px;
            left: -11px;
            position: absolute;
            top: 15px;
            width: 20px;
            @media (min-width: $screen-sm-min) {
                top: 30px;
            }
        }
    }
    &__img {
        background-color: $primary;
        height: 50px;
        margin-bottom: 15px;
        position: relative;
        text-align: center;
        width: 50px;
        @media (min-width: $screen-sm-min) {
            height: 80px;
            left: 30px;
            position: absolute;
            top: 0;
            width: 80px;
        }
        &::before {
            border-bottom: 10px solid transparent;
            border-left: 10px solid transparent;
            border-right: 10px solid $primary;
            border-top: 10px solid transparent;
            bottom: 0;
            content: "";
            height: 0;
            left: -20px;
            margin: auto;
            position: absolute;
            top: 0;
        }
        .fa {
            line-height: 50px;
            @media (min-width: $screen-sm-min) {
                font-size: 26px;
                line-height: 80px;
            }
        }
    }
    &__desc {
        h3 {
            margin-bottom: 10px;
            margin-top: 0;
        }
    }
}