.animated-scroll-to-top-button {
    @include transition-duration(.4s);
    @include transition-property(bottom, opacity, visibility, z-index);
    @include transition-timing(linear);
    background-color: $animated-scroll-to-top-button-background;
    border-radius: 50%;
    bottom: 0;
    box-shadow: 0 0 20px rgba($animated-scroll-to-top-button-shadow, 0.6);
    cursor: pointer;
    height: 50px;
    opacity: 0;
    position: fixed;
    right: 10px;
    text-align: center;
    visibility: hidden;
    width: 50px;
    z-index: -999;
    @media (min-width: $screen-sm-min) {
        right: 20px;
    }
    &::before {
        border-bottom: 10px solid $animated-scroll-to-top-button;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        content: "";
        display: inline-block;
        height: 0px;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        top: 9px;
        width: 0px;
    }
    &.active {
        bottom: 10px;
        opacity: 1;
        visibility: visible;
        z-index: 999;
        @media (min-width: $screen-sm-min) {
            bottom: 20px;
        }
    }
}