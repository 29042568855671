.pd6500i-zone-indications {
    margin: 30px auto 0;
    max-width: 381px;
    position: relative;
    &__zones {
        bottom: 0;
        left: 18%;
        position: absolute;
        right: 18%;
        top: 13%;
        span {
            height: 20px;
            margin: auto;
            position: absolute;
            width: 20px;
            $items: 33;
            $columns: 3;
            $top-left: 0%;
            $top-center: 0%;
            $top-right: 0%;
            @for $i from 1 through $items {
                @if $i <=11 {
                    &:nth-child(#{$i}) {
                        left: 0;
                        top: $top-left;
                    }
                    $top-left: $top-left+(100/($items/$columns));
                }
                @else if $i <=22 {
                    &:nth-child(#{$i}) {
                        left: 0;
                        right: 0;
                        text-align: center;
                        top: $top-center;
                    }
                    $top-center: $top-center+(100/($items/$columns));
                }
                @else if $i <=33 {
                    &:nth-child(#{$i}) {
                        right: 0;
                        top: $top-right;
                    }
                    $top-right: $top-right+(100/($items/$columns));
                }
            }
            &::before {
                background-color: $primary;
                border-radius: 50%;
                bottom: 0;
                content: "";
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 1;
            }
            &::after {
                @keyframes blinking-zone-indications {
                    70% {
                        @include transform-scale(1);
                        opacity: 1;
                    }
                    100% {
                        @include transform-scale(3);
                        opacity: 0;
                    }
                }
                @include animation-duration(3s);
                @include animation-iteration-count(infinite);
                @include animation-name(blinking-zone-indications);
                @include animation-timing-function(linear);
                border-radius: 50%;
                border: 5px solid $primary;
                bottom: 1px;
                content: "";
                left: 1px;
                position: absolute;
                right: 1px;
                top: 1px;
                z-index: 0;
            }
        }
    }
}