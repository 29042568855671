.testimonials {
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin: 0 -15px 30px;
    overflow: hidden;
    padding: 0;
    &__item {
        float: left;
        margin-top: 30px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        @media (min-width: $screen-sm-min) {
            width: 33.33333%;
        }
    }
    &__author {
        border-radius: 50%;
    }
    &__quote {
        font-style: italic;
        font-weight: 300;
        margin-top: 15px;
        text-align: center;
        span {
            display: block;
            font-style: normal;
            font-weight: 700;
            margin-top: 15px;
        }
    }
}