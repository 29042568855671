.dimensions {
    margin: 80px auto 0;
    position: relative;
    @media (min-width: $screen-sm-min) {
        margin-top: 60px;
    }
    &--superscannerv {
        max-width: 900px;
    }
    &--superwand {
        max-width: 806px;
    }
    &--thd {
        max-width: 850px;
    }
    &__image {
        margin-bottom: 30px;
        padding-right: 80px;
    }
    &__axis {
        background-color: $danger;
        position: absolute;
        span {
            display: inline-block;
            font-size: 18px;
            font-weight: 700;
            margin: auto;
            position: absolute;
        }
        &--x {
            height: 3px;
            left: 0;
            right: 80px;
            top: -20px;
            &::before,
            &::after {
                border-bottom: 9px solid transparent;
                border-top: 9px solid transparent;
                content: "";
                position: absolute;
                top: -7px;
            }
            &::before {
                border-left: 9px solid transparent;
                border-right: 9px solid $danger;
                left: -12px;
            }
            &::after {
                border-left: 9px solid $danger;
                border-right: 9px solid transparent;
                right: -12px;
            }
            span {
                left: 0;
                right: 0;
                text-align: center;
                top: -30px;
                width: 50%;
            }
        }
        &--y {
            bottom: 0;
            right: 60px;
            top: 0;
            width: 3px;
            &::before,
            &::after {
                border-left: 9px solid transparent;
                border-right: 9px solid transparent;
                content: "";
                left: -7px;
                position: absolute;
            }
            &::before {
                border-bottom: 9px solid $danger;
                border-top: 9px solid transparent;
                top: -12px;
            }
            &::after {
                border-bottom: 9px solid transparent;
                border-top: 9px solid $danger;
                bottom: -12px;
            }
            span {
                bottom: 0;
                height: 26px;
                left: 15px;
                top: 0;
            }
        }
    }
}