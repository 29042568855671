.applications {
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin: 15px -15px;
    overflow: hidden;
    padding: 0;
    >li {
        float: left;
        margin-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        @media (min-width: $screen-sm-min) {
            width: 50%;
        }
        @media (min-width: $screen-md-min) {
            width: 33.33333%;
        }
    }
    &__item {
        background-color: $primary;
        padding: 20px;
    }
    &__icon {
        display: block;
        margin-bottom: 15px;
        text-align: center;
        .fa {
            font-size: 56px;
        }
    }
    &__caption {
        font-size: 18px;
        font-weight: 700;
        margin: 0;
        text-align: center;
    }
}