.blockquote {
    margin-top: 60px;
    text-align: center;
    &__author {
        @media (min-width: $screen-sm-min) {
            display: inline-block;
            vertical-align: middle;
        }
    }
    &__text {
        border-width: 0;
        font-size: 20px;
        font-style: italic;
        font-weight: 300;
        margin: 0;
        padding: 15px 30px;
        position: relative;
        @media (min-width: $screen-sm-min) {
            display: inline-block;
            margin-left: 15px;
            padding: 15px 50px;
            text-align: left;
            vertical-align: middle;
            width: 70%;
        }
        &::before,
        &::after {
            color: $primary;
            font-family: $font-family-fontawesome;
            font-size: 30px;
            font-style: normal;
            position: absolute;
            @media (min-width: $screen-sm-min) {
                font-size: 42px;
            }
        }
        &::before {
            content: "\f10d";
            left: 0;
            top: -18px;
            @media (min-width: $screen-sm-min) {
                top: -30px;
            }
        }
        &::after {
            bottom: -18px;
            content: "\f10e";
            right: 0;
            @media (min-width: $screen-sm-min) {
                bottom: -30px;
            }
        }
        span {
            display: block;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            margin-top: 15px;
            @media (min-width: $screen-sm-min) {
                text-align: right;
            }
        }
    }
}