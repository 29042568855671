.references {
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin: 0 -15px;
    overflow: hidden;
    padding: 0;
    &__item {
        float: left;
        margin-top: 30px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        @media (min-width: $screen-sm-min) {
            margin-top: 0;
            width: 50%;
        }
        &:first-child {
            margin-top: 0;
        }
    }
    figure {
        img {
            padding: 15px;
            background-color: $white;
            border-bottom: 5px solid $primary;
        }
        figcaption {
            margin-top: 20px;
            >h3 {
                font-size: 18px;
                margin: 0;
                text-align: center;
            }
        }
    }
    .modal-content {
        border-radius: 0;
        .modal-header {
            background-color: $primary;
            .close {
                opacity: 1;
                text-shadow: unset;
            }
        }
    }
}