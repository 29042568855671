/* Base */

$black: #000000;
$color-1: #2c2c2c;
$color-2: #d7d7d7;
$color-3: #e1e1e1;
$danger: #f42441;
$default: #c6af22;
$info: #24a9f4;
$primary: #f4d724;
$success: #a9f424;
$warning: #f46f24;
$white: #ffffff;

/* Links */

$link-hover: darken($primary, 30%);
$link: darken($primary, 20%);

/* Alerts */

$alert-danger: desaturate($danger, 50%);
$alert-info: desaturate($info, 50%);
$alert-success: desaturate($success, 50%);
$alert-warning: desaturate($warning, 50%);

/* Pages */

$page-background: #f0f0f0;

/* Preloader */

$preloader-background-color: $color-1;
$preloader-spinner-border: $primary;

/* Responsive Navigation */

$responsive-navigation-background: $color-1;
$responsive-navigation-contact: $primary;
$responsive-navigation-hamburger: #ffffff;
$responsive-navigation-main-sidebar-icon: #ffffff;
$responsive-navigation-main-sidebar: $responsive-navigation-background;
$responsive-navigation-main: $responsive-navigation-background;
$responsive-navigation-text-hover-background: $primary;
$responsive-navigation-text-hover: $color-1;
$responsive-navigation-text: $white;

/* Carousel */

$carousel-caption-heading: $primary;
$carousel-caption-lead: $white;
$carousel-caption-service: $primary;
$carousel-caption: #000000;
$carousel-indicator-active: $carousel-caption-heading;
$carousel-indicator-border: $carousel-caption-lead;
$carousel-inner: #000000;

/* Footer */

$footer-background: $color-1;
$footer-contact-background: $white;
$footer-text-mobile: $white;
$footer-text-desktop: initial;
$footer-validation-notice-error: $danger;
$footer-validation-notice-success:$success;

/* Buttons */

$button-default: $default;
$button-primary: $primary;
$button-success: $success;
$button-info: $info;
$button-warning: $warning;
$button-danger: $danger;

/* Animated scroll to top button */

$animated-scroll-to-top-button-background: $primary;
$animated-scroll-to-top-button-shadow: $color-1;
$animated-scroll-to-top-button: $color-1;