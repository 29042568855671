.metal-detectors-applications {
    &__item {
        margin-top: 30px;
        h3 {
            margin: 0 0 15px;
        }
        .image-block {
            margin-top: 30px;
            @media (min-width: $screen-lg-min) {
                margin-top: 50px;
            }
        }
    }
}