body {
    font-family: $font-family-base;
    font-size: 16px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
    margin-top: 30px;
    margin-bottom: 20px;
}

.h1,
h1 {
    font-size: 38px;
}

.h2,
h2 {
    font-size: 32px;
}

.h3,
h3 {
    font-size: 26px;
}

p {
    margin: 0 0 15px;
}

.lead {
    font-size: 18px;
    font-weight: 700;
}