.terms-conditions {
    ol {
        list-style-image: none;
        list-style-position: inside;
        list-style-type: upper-roman;
        margin: 0 0 30px;
        padding: 0;
        &:last-child {
            margin: 0;
        }
        >li {
            font-weight: 700;
            padding: 5px 0;
            text-transform: uppercase;
            >ol,
            >ul {
                list-style-type: decimal;
                margin: 0;
                padding-left: 50px;
                >li {
                    font-weight: 400;
                    text-transform: none;
                    >ul {
                        list-style-type: lower-latin;
                        margin: 5px 0;
                    }
                }
            }
        }
    }
    span {
        word-wrap: break-word;
    }
}